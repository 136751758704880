@import "~antd/dist/antd.less";
@import "./partials/collapseBox.less";
@import "./partials/dashboard.less";

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
.ant-layout-sider-zero-width-trigger {
  top: 5px;
  right: 10px;
  color: #001529;
  background: none;
  font-size: 20px;
}
// .ant-layout-sider-collapsed > .ant-layout-sider-zero-width-trigger {
//   right: -50px;
// }

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 38px;
  margin-left: -16px;
  margin-top: -19px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-avatar {
  float: right;
  margin-right: 30px;
  margin-top: 15px;
}

.ant-dropdown-menu {
  border-radius: 6px;
}
.login-form button[type="submit"] {
  margin-bottom: 20px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.clickRowStyl {
  background: #00b4ed;
}
.ant-table * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.ant-table-row-selected > td {
  background: #d8e6df !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #1DA57A;